/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { ColumnCover, Column, Menu, ColumnWrapper, Subtitle, MenuWrap, MenuButton, MenuWrapBox, MenuButtonClose, Button, Fullmap, FullmapWrap, ColumnWrap } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Organizace"}>
        <ColumnCover name={"menu"}>
        </ColumnCover>


        <Column className="--menu pb--10 pt--10" menu={true} name={"4oxgusb6x36"}>
          
          <Menu >
            
            <ColumnWrapper className="menu-logo-box">
              
              <Subtitle className="subtitle-box" content={"<a href=\"/\">Mateřská škola <span style=\"font-weight: bold;\">Litultovice</span></a><br>"}>
              </Subtitle>

            </ColumnWrapper>

            <MenuWrap >
              
              <MenuButton >
              </MenuButton>

              <MenuWrapBox >
                
                <MenuButtonClose >
                </MenuButtonClose>

                <Button className="btn-box btn-box--hvr3 btn-box--cbtn2 btn-box--sbtn2" href={"/"} target={""} content={"<span style=\"color: rgb(115, 71, 156);\">Aktuálně</span>"}>
                </Button>

                <Button className="btn-box btn-box--hvr3 btn-box--cbtn2 btn-box--sbtn2" href={"/dokumenty"} target={""} content={"<span style=\"color: rgb(235, 131, 207);\">Dokumenty</span>"}>
                </Button>

                <Button className="btn-box btn-box--cbtn2 btn-box--sbtn2" href={"/pro-rodice"} target={""} content={"<span style=\"color: rgb(55, 116, 222); text-decoration: underline;\">Pro <span style=\"text-decoration: underline;\">rodiče</span></span>"}>
                </Button>

                <Button className="btn-box btn-box--hvr3 btn-box--cbtn2 btn-box--sbtn2" href={"/fotogalerie"} target={""} content={"<span style=\"caret-color: rgb(221, 116, 15); color: rgb(221, 116, 15);\">Fotogalerie</span>"}>
                </Button>

                <Button className="btn-box btn-box--hvr3 btn-box--cbtn2 btn-box--sbtn2" href={"/o-nas"} target={""} content={"<span style=\"color: rgb(16, 156, 69);\">O nás</span>"}>
                </Button>

                <Button className="btn-box btn-box--hvr3 btn-box--cbtn2 btn-box--sbtn2" href={"/kontakt"} target={""} content={"<span style=\"color: rgb(213, 4, 57);\">Kontakt</span>"}>
                </Button>

              </MenuWrapBox>

            </MenuWrap>

          </Menu>

        </Column>


        <Column className="css-qbd661 --justify pb--80 pr--0 pt--80" name={"informace"} style={{"backgroundColor":"var(--color-variable-1)"}} parallax={false} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/13139/ea2cfab5debd4374b3229152f95e089c_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/13139/ea2cfab5debd4374b3229152f95e089c_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/13139/ea2cfab5debd4374b3229152f95e089c_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/13139/ea2cfab5debd4374b3229152f95e089c_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/13139/ea2cfab5debd4374b3229152f95e089c_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/13139/ea2cfab5debd4374b3229152f95e089c_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/13139/ea2cfab5debd4374b3229152f95e089c_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/13139/ea2cfab5debd4374b3229152f95e089c_s=3000x_.jpg);
    }
  
    `}>
        </Column>


        <Fullmap className="--style2" name={"xrq5c45sffc"}>
          
          <FullmapWrap className="--full">
          </FullmapWrap>

        </Fullmap>


        <ColumnCover name={"8nlzg5iq4eb"}>
        </ColumnCover>


        <Column className="--menu pb--10 pt--10" menu={true} name={"6d05oq161a6"} style={{"backgroundColor":"rgba(222,222,222,1)"}}>
          
          <Menu >
            
            <ColumnWrapper className="menu-logo-box">
              
              <Subtitle className="subtitle-box" content={"<span style=\"font-weight: bold;\">Pro rodiče</span>"}>
              </Subtitle>

            </ColumnWrapper>

            <MenuWrap >
              
              <MenuButton >
              </MenuButton>

              <MenuWrapBox className="--style3">
                
                <MenuButtonClose >
                </MenuButtonClose>

                <Button className="btn-box btn-box--hvr3 btn-box--cbtn2 btn-box--sbtn2 btn-box--center" href={"/pro-rodice"} target={""} content={"Informace"}>
                </Button>

                <Button className="btn-box btn-box--hvr3 btn-box--cbtn2 btn-box--sbtn2" href={"/stravovani"} target={""} content={"Stravování"}>
                </Button>

                <Button className="btn-box btn-box--hvr3 btn-box--cbtn2 btn-box--sbtn2" href={"/formulare"} target={""} content={"Formuláře"}>
                </Button>

              </MenuWrapBox>

            </MenuWrap>

          </Menu>

        </Column>


        <Column className="pb--40 pt--40" name={"k8otoco84vg"}>
          
          <ColumnWrap className="column__flex --center el--1 flex--bottom" style={{"maxWidth":800}} columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Subtitle className="subtitle-box" content={"<span style=\"font-weight: bold;\">Organizace dne<br><br><br><br><br><br><br><br></span>"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}